import {CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {RouteEnum} from '../enums/route.enum';
import {AuthService} from '../services/auth.service';
import {DialogInjectorService} from '../services/dialog-injector.service';

// TODO uncomment after keycloak integration
// export const authGuard: CanActivateFn = (): Observable<boolean> => inject(AuthService).checkAuthorization();

export const authGuard: CanActivateFn = (_, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthService);
  if (!authService.isAuthenticated) {
    if (state.url === RouteEnum.Home || state.url === `/${RouteEnum.Dashboard}`) {
      const router = inject(Router);
      router.navigate([RouteEnum.Auth]);
    } else if (state.url === RouteEnum.Auth) {
      return of(false);
    }
    return inject(DialogInjectorService)
      .openAuthDialog()
      .pipe(map(res => !!res));
  } else {
    try {
      return authService.fetchProfile().pipe(map(profile => !!profile));
    } catch (e) {
      console.error(e);
      return of(false);
    }
  }
};
